<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      <Header
        :meta="meta"
        :page-title="moduleConfig.page_title"
        :page-image="moduleConfig.page_image"
        :header-section="moduleConfig.header_section"
      />
      <Tabs :tabs="tabs" :currentTab="currentTab" />

      <div class="content">
        <router-view />
      </div>
    </div>
    <Alert ref="alert" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import currency from "@/mixins/liff/currency";
import themeColor from "@/mixins/liff/themeColor";
import orderList from "@/mixins/liff/orderList/main";
import Alert from "@/components/Page/Liff/Shared/Alert";
import Header from "@/components/Page/Liff/Shared/HeaderV2";
import Tabs from "@/components/Page/Liff/Shared/TabsV2";
import _ from "lodash";

export default {
  mixins: [currency, themeColor, orderList],
  components: { Alert, Header, Tabs },
  ...mapGetters({
    themeConfig: "liffGeneral/themeConfig",
  }),
  data() {
    return {
      themeConfigPage: "order",
    };
  },
  provide() {
    return {
      $alert: (message, title = "") => this.$refs.alert.show(message, title),
      $error: () => {},
    };
  },
  computed: {
    tabs: function () {
      return _.sortBy(this.moduleConfig.data_source, "order");
    },
    currentTab: function () {
      return this.$route.query.source;
    },
  },
  mounted() {
    if (this.$route.name == "LiffOrderListPortal") {
      const isEndWithSlash = this.$route.path.slice(-1) == "/";
      const tab = this.tabs.sort((a, b) => a.order - b.order)[0];
      const path = isEndWithSlash
        ? tab.path
        : `${this.$route.path}/${tab.path}`;
      this.$router.push({ path, query: { source: tab.id } });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout {
  --s-primary: #de006f;
  --s-warning: #ed6c00;
  --s-danger: #fe0000;
  --s-gray-darker: #363636;
  --s-gray-dark: #595959;
  --s-gray: #909399;
  --s-gray-light: #c0c4cc;
  --s-gray-lighter: #ecedef;
}

.s-layout {
  color: var(--liff-primary_text_color) !important;
  background-color: var(--liff-layout_bg_color) !important;
  min-height: 100dvh;
}

.s-container {
  padding-bottom: 1.5rem;
}

.content {
  padding-bottom: 24px;
  background: var(--liff-content_bg_color);
}
</style>
